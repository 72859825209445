import React from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import EshopForm from 'components/EshopForm';
import Skeleton from 'react-loading-skeleton';
import ErrorSkeleton from 'components/ErrorSkeleton';

const GET_ESHOP_QUERY = gql`
  query getEshop($id: ID!) {
    getEshop(id: $id) {
      id
      name
      url
      platform
      feedUrl
      feedUpdateFrequency
      showRecommendations
    }
  }
`;

const UPDATE_ESHOP_MUTATION = gql`
  mutation updateEshop($input: UpdateEshopInput!) {
    updateEshop(input: $input) {
      id
      name
      url
      feedUrl
      platform
      domain
      feedUpdateFrequency
      showRecommendations
    }
  }
`;

function EditEshopForm({ id }) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { data, loading, error } = useQuery(GET_ESHOP_QUERY, {
    variables: {
      id,
    },
  });
  const [updateEshop] = useMutation(UPDATE_ESHOP_MUTATION);

  const onSubmit = async (d) => {
    try {
      await updateEshop({
        variables: {
          input: {
            id,
            ...d,
          },
        },
      });
      addToast(t('eshops.create_form.eshop_updated'), {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      addToast(t('eshops.create_form.failed_to_update_eshop', { err }), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  if (loading) return <Skeleton height={400} />;
  if (error)
    return (
      <ErrorSkeleton
        style={{
          height: 400,
        }}
      >
        <h6>{t('errors.data_error')}</h6>
      </ErrorSkeleton>
    );
  return (
    <EshopForm
      onSave={onSubmit}
      defaultValues={data?.getEshop}
      disabledFields={['url', 'platform']}
    />
  );
}

EditEshopForm.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EditEshopForm;
