import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, Button, Form, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

// TODO: add phone and email validator
function AccountForm({ onDiscard, onSave, defaultValues, disabled }) {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, trigger, reset, formState } = useForm(
    {
      defaultValues,
    }
  );

  const onSubmit = async (data) => {
    onSave(data);
  };

  const handleDiscardClick = () => {
    onDiscard();
    reset();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="p-3 card--glass">
      <Row>
        <Col>
          <FormGroup
            className={classnames({
              'has-danger': errors.email,
            })}
          >
            <Label for="email" className="pb-2">
              {t('account.update_form.email')}
            </Label>
            <Input
              className={classnames({
                'form-control-danger': errors.email,
              })}
              id="email"
              name="email"
              type="text"
              placeholder={t('account.update_form.email_placeholder')}
              onBlur={() => trigger('email')}
              innerRef={register({
                required: t('form.required'),
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t('form.must_be_valid_email'),
                },
              })}
              disabled={disabled}
            />
            <div className="form-control-feedback pt-1 text-left">
              {errors.email && errors.email.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup
            className={classnames({
              'has-danger': errors.phone,
            })}
          >
            <Label for="phone" className="pb-2">
              {t('account.update_form.phone')}
            </Label>
            <Input
              className={classnames({
                'form-control-danger': errors.phone,
              })}
              id="phone"
              name="phone"
              type="text"
              placeholder={t('account.update_form.phone_placeholder')}
              onBlur={() => trigger('phone')}
              innerRef={register({
                pattern: {
                  value: /\+\d*/,
                  message: t('form.must_be_valid_phone'),
                },
              })}
              disabled={disabled}
            />
            <div className="form-control-feedback pt-1 text-left">
              {errors.phone && errors.phone.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="profile-edit-form__button-group">
          <Button
            className={classnames('btn-fill btn-round', {
              disabled: Object.keys(formState.touched).length === 0,
            })}
            color="danger"
            onClick={handleDiscardClick}
          >
            {t('form.discard')}
          </Button>
          <Button
            className="btn-fill btn-round"
            color="success"
            disabled={!formState.isDirty}
          >
            {t('form.save')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

AccountForm.propTypes = {
  onDiscard: PropTypes.func,
  onSave: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValues: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
};

AccountForm.defaultProps = {
  onDiscard: () => console.log('on discard handler'),
  onSave: () => console.log('on save handler'),
  defaultValues: {},
  disabled: false,
};

export default AccountForm;
