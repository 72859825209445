import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAmplifyAuth from 'hooks/useAmplifyAuth';
import { gql, useLazyQuery } from '@apollo/react-hooks';
import * as _ from 'lodash';

const LIST_ACCOUNTS = gql`
  query listAccounts($cognitoClientId: String!) {
    listAccounts(filter: { cognitoClientId: { eq: $cognitoClientId } }) {
      items {
        id
        cognitoClientId
        email
        createdAt
      }
    }
  }
`;

const accontContext = createContext();

function useProvideAccount() {
  const [account, setAccount] = useState('');
  const {
    state: { user },
  } = useAmplifyAuth();
  const [listAccounts, { data }] = useLazyQuery(LIST_ACCOUNTS);

  useEffect(() => {
    if (user) {
      const cognitoClientId = user.attributes.sub;
      listAccounts({
        variables: {
          cognitoClientId,
        },
      });
    }
  }, [user, listAccounts]);

  useEffect(() => {
    if (data) {
      const newestAccount = _.maxBy(data.listAccounts.items, 'createdAt');
      setAccount(newestAccount);
    }
  }, [data]);

  return {
    account,
  };
}

export function AccountProvider({ children }) {
  const account = useProvideAccount();
  return (
    <accontContext.Provider value={account}>{children}</accontContext.Provider>
  );
}

AccountProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useAccount = () => {
  return useContext(accontContext);
};
