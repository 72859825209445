import React from 'react';
import { Card, Container, Row, Col } from 'reactstrap';
import ForgotPasswordForm from 'components/ForgotPasswordForm';
import { useTranslation } from 'react-i18next';

function ForgotPasswordPage() {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <Col className="ml-auto mr-auto" lg="4">
          <Card className="card-register ml-auto mr-auto">
            <h3 className="title mx-auto text-uppercase">
              {t('forgot_password.forgot_password')}
            </h3>
            <ForgotPasswordForm />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPasswordPage;
