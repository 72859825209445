import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import AccountSettings from 'components/AccountSettings';
import { useAccount } from 'hooks/useAccount';

function AccountPage() {
  const { t } = useTranslation();
  const { account } = useAccount();

  return (
    <Container className="pt-3">
      <Row className="mb-3 d-flex flex-row align-items-baseline">
        <Col>
          <h6 className="text-light-blue">{t('general.account')}</h6>
        </Col>
      </Row>
      <Row>
        <Col>{account && <AccountSettings id={account.id} />}</Col>
      </Row>
    </Container>
  );
}

export default AccountPage;
