import React from 'react';
import { Redirect } from 'react-router-dom';
// import { Container, Row, Col } from 'reactstrap';

// import HomepageInvitation from 'components/HomepageInvitation';

function HomePage() {
  return <Redirect to="/eshops" />;
  // return (
  //   <Container className="pt-5">
  //     <Row className="pt-4">
  //       <Col className="ml-auto mr-auto text-center" md="6">
  //         <HomepageInvitation />
  //       </Col>
  //     </Row>
  //   </Container>
  // );
}

export default HomePage;
