import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, Button, Form, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { useToasts } from 'react-toast-notifications';
import { confirmSignUp } from 'utils/auth';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';

function ConfirmSignUpForm({ onConfirmSignUpSuccess }) {
  const { t } = useTranslation();
  const [isVerifying, setIsVerifying] = useState(false);
  const { search } = useLocation();
  const { email } = qs.parse(search);
  const { addToast } = useToasts();
  const { register, handleSubmit, errors, trigger, reset } = useForm();

  const onSubmit = async (data) => {
    const { code } = data;

    setIsVerifying(true);
    try {
      await confirmSignUp(email, code);
      addToast(t('confirm_signup.verify_account_success'), {
        appearance: 'success',
        autoDismiss: true,
      });
      reset();
      onConfirmSignUpSuccess();
    } catch (error) {
      addToast(t('confirm_signup.verify_account_fail', { error }), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    setIsVerifying(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="register-form">
      <Row>
        <Col>
          <FormGroup
            className={classnames({
              'has-danger': errors.email,
            })}
          >
            <Label for="email" className="pb-2">
              {t('confirm_signup.email')}
            </Label>
            <Input
              className={classnames({
                'form-control-danger': errors.email,
              })}
              id="email"
              name="email"
              type="text"
              placeholder={t('confirm_signup.email_placeholder')}
              onBlur={() => trigger('email')}
              innerRef={register({
                required: t('form.required'),
              })}
              disabled
              defaultValue={email}
            />
            <div className="form-control-feedback pt-1 text-left">
              {errors.email && errors.email.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup
            className={classnames({
              'has-danger': errors.code,
            })}
          >
            <Label for="password" className="pb-2">
              {t('confirm_signup.code')}
            </Label>
            <Input
              className={classnames({
                'form-control-danger': errors.code,
              })}
              id="code"
              name="code"
              type="text"
              onBlur={() => trigger('code')}
              innerRef={register({
                required: t('form.required'),
              })}
            />
            <div className="form-control-feedback pt-1 text-left">
              {errors.code && errors.code.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            block
            className="btn-round"
            color="danger"
            disabled={isVerifying}
          >
            {isVerifying ? (
              <i className="fa fa-spinner fa-spin" aria-hidden="true" />
            ) : (
              t('confirm_signup.verify')
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

ConfirmSignUpForm.propTypes = {
  onConfirmSignUpSuccess: PropTypes.func,
};

ConfirmSignUpForm.defaultProps = {
  onConfirmSignUpSuccess: () => console.log('onConfirmSignUpSuccess triggered'),
};

export default ConfirmSignUpForm;
