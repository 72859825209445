import React from 'react';
import { Card, Container, Row, Col } from 'reactstrap';
import RegisterForm from 'components/RegisterForm';
import { useTranslation } from 'react-i18next';

function RegisterPage() {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <Col className="ml-auto mr-auto text-uppercase" lg="4">
          <Card className="card-register ml-auto mr-auto">
            <h3 className="title mx-auto">{t('register.register')}</h3>
            <RegisterForm />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default RegisterPage;
