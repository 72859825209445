import { ApolloClient } from '@apollo/client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, concat } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import awsconfig from 'aws-exports';
import isValidJWT from './isValidJWT';

const noCacheOptions = {
  watchQuery: {
    errorPolicy: 'ignore',
  },
  query: {
    errorPolicy: 'all',
  },
};

function loadAccessToken() {
  const userName = localStorage.getItem(
    `CognitoIdentityServiceProvider.${awsconfig.aws_user_pools_web_client_id}.LastAuthUser`
  );
  const accessToken = localStorage.getItem(
    `CognitoIdentityServiceProvider.${awsconfig.aws_user_pools_web_client_id}.${userName}.accessToken`
  );
  if (!accessToken) {
    throw new Error('unable to load accessToken from localStorage');
  }
  return accessToken;
}

const createApolloClient = ({ uri, cache }) => {
  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext({
      headers: {
        Authorization: `${loadAccessToken()}`,
      },
    });

    return forward(operation);
  });

  const uploadLink = createUploadLink({
    uri: uri || awsconfig.aws_appsync_graphqlEndpoint,
    credentials: 'same-origin',
    headers: {
      'keep-alive': 'true',
    },
  });

  const client = new ApolloClient({
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) {
          console.error(`[Network error]: ${networkError}`);
          if (
            networkError.statusCode === 401 &&
            !isValidJWT(localStorage.getItem('token'))
          ) {
            window.location.replace('/login');
          }
        }
      }),
      concat(authMiddleware, uploadLink),
    ]),
    cache: new InMemoryCache(),
    defaultOptions: !cache && noCacheOptions,
  });

  return client;
};

export default createApolloClient;
