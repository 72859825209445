import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  Collapse,
  NavbarBrand,
  Navbar as ReactstrapNavbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from 'reactstrap';

function Navbar() {
  const { t } = useTranslation();
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle('nav-open');
  };

  return (
    <ReactstrapNavbar
      className={(classnames('fixed-top'), 'navbar-dark')}
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            to="/"
            title="Coded by Creative Tim"
            tag={Link}
          >
            <img
              alt="..."
              style={{
                height: '1rem',
              }}
              // className="img-circle img-no-padding img-responsive"
              src={require('assets/img/logo_navidator.png')}
            />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames('navbar-toggler navbar-toggler', {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink to="/eshops" tag={Link}>
                <i className="nc-icon nc-cart-simple mr-2" />
                {t('general.eshops')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/account" tag={Link}>
                <i className="nc-icon nc-single-02 mr-2" />
                {t('general.account')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/logout" tag={Link}>
                <i className="nc-icon nc-button-power mr-2" />
                {t('general.logout')}
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </ReactstrapNavbar>
  );
}

export default memo(Navbar);
