import React, { useState } from 'react';
import { Row, Col, Input, Button, Form, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { useToasts } from 'react-toast-notifications';
import { forgotPassword, forgotPasswordSubmit } from 'utils/auth';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function ForgotPasswordForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const { addToast } = useToasts();
  const [codeSent, setCodeSent] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    trigger,
    reset,
    setError,
  } = useForm();

  const onSubmit = async (data) => {
    const { email, code, password, passwordcheck } = data;

    if (password !== passwordcheck) {
      setError('passwordcheck', {
        types: 'manual',
        message: t('forgot_password.passwordcheck_not_same_as_password'),
      });
      return;
    }

    if (!codeSent) {
      try {
        await forgotPassword(email);
        addToast(t('forgot_password.sent_reset_code_success'), {
          appearance: 'success',
          autoDismiss: true,
        });
        setCodeSent(true);
      } catch (error) {
        addToast(t('forgot_password.sent_reset_code_fail', { error }), {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      try {
        await forgotPasswordSubmit(email, code, password);
        addToast(t('forgot_password.reset_password_success'), {
          appearance: 'success',
          autoDismiss: true,
        });
        reset();
        history.push({
          pathname: '/login',
        });
      } catch (error) {
        addToast(t('forgot_password.reset_password_success', { error }), {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="register-form">
      <Row key="email">
        <Col>
          <FormGroup
            className={classnames({
              'has-danger': errors.email,
            })}
          >
            <Label for="email" className="pb-2">
              {t('forgot_password.email')}
            </Label>
            <Input
              className={classnames({
                'form-control-danger': errors.email,
              })}
              id="email"
              name="email"
              type="text"
              placeholder={t('forgot_password.email')}
              onBlur={() => trigger('email')}
              innerRef={register({
                required: t('form.required'),
              })}
            />
            <div className="form-control-feedback pt-1 text-left">
              {errors.email && errors.email.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      {codeSent && (
        <>
          <Row>
            <Col>
              <FormGroup
                className={classnames({
                  'has-danger': errors.code,
                })}
              >
                <Label for="password" className="pb-2">
                  {t('forgot_password.code')}
                </Label>
                <Input
                  className={classnames({
                    'form-control-danger': errors.code,
                  })}
                  id="code"
                  name="code"
                  type="text"
                  onBlur={() => trigger('code')}
                  innerRef={register({
                    required: t('form.required'),
                  })}
                />
                <div className="form-control-feedback pt-1 text-left">
                  {errors.code && errors.code.message}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup
                className={classnames({
                  'has-danger': errors.password,
                })}
              >
                <Label for="password" className="pb-2">
                  {t('forgot_password.password')}
                </Label>
                <Input
                  className={classnames({
                    'form-control-danger': errors.password,
                  })}
                  id="password"
                  name="password"
                  type="password"
                  onBlur={() => trigger('password')}
                  innerRef={register({
                    required: t('form.required'),
                  })}
                />
                <div className="form-control-feedback pt-1 text-left">
                  {errors.password && errors.password.message}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup
                className={classnames({
                  'has-danger': errors.passwordcheck,
                })}
              >
                <Label for="passwordcheck" className="pb-2">
                  {t('forgot_password.password_check')}
                </Label>
                <Input
                  className={classnames({
                    'form-control-danger': errors.passwordcheck,
                  })}
                  id="passwordcheck"
                  name="passwordcheck"
                  type="password"
                  onBlur={() => trigger('passwordcheck')}
                  innerRef={register({
                    required: t('form.required'),
                  })}
                />
                <div className="form-control-feedback pt-1 text-left">
                  {errors.passwordcheck && errors.passwordcheck.message}
                </div>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col>
          <Button block className="btn-round" color="danger">
            {t('forgot_password.verify')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default ForgotPasswordForm;
