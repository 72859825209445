import React from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import AccountForm from 'components/AccountForm';
import Skeleton from 'react-loading-skeleton';
import ErrorSkeleton from 'components/ErrorSkeleton';

const GET_ACCOUNT_QUERY = gql`
  query getAccount($id: ID!) {
    getAccount(id: $id) {
      id
      email
      phone
    }
  }
`;

const UPDATE_ACCOUNT_MUTATION = gql`
  mutation updateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      id
      email
      phone
    }
  }
`;

function EditAccountForm({ id }) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { data, loading, error } = useQuery(GET_ACCOUNT_QUERY, {
    variables: {
      id,
    },
  });
  const [updateAccount] = useMutation(UPDATE_ACCOUNT_MUTATION);

  const onSubmit = async (d) => {
    try {
      await updateAccount({
        variables: {
          input: {
            id,
            ...d,
          },
        },
      });
      addToast(t('account.update_form.account_update_success'), {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (e) {
      addToast(t('account.update_form.account_update_fail', { e }), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  if (loading) return <Skeleton height={400} />;
  if (error)
    return (
      <ErrorSkeleton
        style={{
          height: 400,
        }}
      >
        <h6>{t('errors.data_error')}</h6>
      </ErrorSkeleton>
    );
  return <AccountForm onSave={onSubmit} defaultValues={data?.getAccount} />;
}

EditAccountForm.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EditAccountForm;
