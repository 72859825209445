import React, { useState } from 'react';
import { Row, Col, Input, Button, Form, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { useToasts } from 'react-toast-notifications';
import { signUp } from 'utils/auth';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function RegisterForm() {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [isSigningUp, setIsSigningUp] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, errors, trigger, reset } = useForm();

  const onSubmit = async (data) => {
    const { email, password } = data;

    setIsSigningUp(true);
    try {
      await signUp(email, password);
      addToast(t('register.register_account_success'), {
        appearance: 'success',
        autoDismiss: true,
      });
      reset();
      history.push({
        pathname: '/confirm-signup',
        search: `?email=${email}`,
      });
    } catch (error) {
      addToast(t('register.register_account_fail', { error }), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    setIsSigningUp(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="register-form">
      <Row>
        <Col>
          <FormGroup
            className={classnames({
              'has-danger': errors.email,
            })}
          >
            <Label for="password" className="pb-2">
              {t('register.email')}
            </Label>
            <Input
              className={classnames({
                'form-control-danger': errors.email,
              })}
              id="email"
              name="email"
              type="email"
              placeholder={t('register.email_placeholder')}
              onBlur={() => trigger('email')}
              innerRef={register({
                required: t('form.required'),
              })}
            />
            <div className="form-control-feedback pt-1 text-left">
              {errors.email && errors.email.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup
            className={classnames({
              'has-danger': errors.password,
            })}
          >
            <Label for="password" className="pb-2">
              {t('register.password')}
            </Label>
            <Input
              className={classnames({
                'form-control-danger': errors.password,
              })}
              id="password"
              name="password"
              type="password"
              onBlur={() => trigger('password')}
              innerRef={register({
                required: t('form.required'),
              })}
            />
            <div className="form-control-feedback pt-1 text-left">
              {errors.password && errors.password.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            block
            className="btn-round"
            color="danger"
            disabled={isSigningUp}
          >
            {isSigningUp ? (
              <i className="fa fa-spinner fa-spin" aria-hidden="true" />
            ) : (
              t('register.register')
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default RegisterForm;
