import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useAccount } from 'hooks/useAccount';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import EshopForm from 'components/EshopForm';
import parsedDomain from 'utils/parsedDomain';

const CREATE_ESHOP_MUTATION = gql`
  mutation createEshop($input: CreateEshopInput!) {
    createEshop(input: $input) {
      id
      name
      url
      feedUrl
      platform
      domain
      status
      feedUpdateFrequency
      lastFeedUpdateAt
    }
  }
`;

const ALL_ESHOPS_QUERY = gql`
  query listEshops {
    listEshops {
      items {
        id
        name
        status
        platform
        url
        lastFeedUpdateAt
      }
    }
  }
`;

function CreateEshopForm() {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { addToast } = useToasts();
  const { account } = useAccount();
  const [createEshop] = useMutation(CREATE_ESHOP_MUTATION, {
    update(cache, { data }) {
      try {
        const createdEshop = data.createEshop;
        const existingListEshops = cache.readQuery({
          query: ALL_ESHOPS_QUERY,
        });
        const newListEshops = {
          ...existingListEshops,
          listEshops: {
            ...existingListEshops.listEshops,
            items: [...existingListEshops.listEshops.items, createdEshop],
          },
        };
        cache.writeQuery({
          query: ALL_ESHOPS_QUERY,
          data: newListEshops,
        });
      } catch (error) {
        console.log('[CACHE] error updating cache:', error);
      }
      // TODO: this can't bew used beacuse of https://github.com/apollographql/apollo-client/issues/6208, follow until solved
      // cache.modify({
      //   fields: {
      //     listEshops(existingListEshops = []) {
      //       console.log('existingListEshops', existingListEshops);
      //       const newEshopRef = cache.writeFragment({
      //         data: newEshop,
      //         fragment: gql`
      //           fragment NewEshop on Eshop {
      //             id
      //             name
      //             url
      //             feedUrl
      //             platform
      //             domain
      //             feedUpdateFrequency
      //           }
      //         `,
      //       });
      //       return [...existingListEshops, newEshopRef];
      //     },
      //   },
      // });
    },
  });

  const onSubmit = async (data) => {
    if (!account.id) {
      const missingAccountError = new Error('no account found');
      addToast(
        t('eshops.create_form.failed_to_create_eshop', {
          error: missingAccountError,
        }),
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );
      return;
    }
    try {
      await createEshop({
        variables: {
          input: {
            ...data,
            domain: parsedDomain(data?.url),
            eshopAccountId: account?.id,
          },
        },
      });
      addToast(t('eshops.create_form.eshop_created'), {
        appearance: 'success',
        autoDismiss: true,
      });
      push({
        pathname: `/eshops`,
      });
    } catch (error) {
      addToast(t('eshops.create_form.failed_to_create_eshop', { error }), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <EshopForm onSave={onSubmit} disabledFields={['showRecommendations']} />
  );
}

export default CreateEshopForm;
