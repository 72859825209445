// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:a18bb8a8-6c24-4184-8e4e-2aae296fdeae",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_BkvJsfW0m",
    "aws_user_pools_web_client_id": "7u328mo03eiarcsh7pv243jeqj",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://zuq77a67nrb2vluzcuu2e676ma.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fakeApiId123456",
    "aws_content_delivery_bucket": "recosystem-20201028122051-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d10jbxhqlfyvav.cloudfront.net"
};


export default awsmobile;
