const feedUpdateFrequencies = [
  {
    value: 1,
    label: 'h_1',
  },
  {
    value: 6,
    label: 'h_6',
  },
  {
    value: 12,
    label: 'h_12',
  },
  {
    value: 24,
    label: 'h_24',
  },
];

export default feedUpdateFrequencies;
