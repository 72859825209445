import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, Button, Form, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import platforms from 'variables/platforms';
import feedUpdateFrequencies from 'variables/feedUpdateFrequencies';
import { useTranslation } from 'react-i18next';
import removeAttributes from 'utils/removeAttributes';
import { values } from 'lodash';

function EshopForm({
  onDiscard,
  onSave,
  defaultValues,
  disabled,
  disabledFields,
}) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    errors,
    trigger,
    watch,
    reset,
    formState,
  } = useForm({
    defaultValues,
  });
  const selectedPlatform = watch('platform');

  const onSubmit = async (d) => {
    const data = removeAttributes(d, disabledFields);
    onSave(data);
  };

  const handleDiscardClick = () => {
    onDiscard();
    reset();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="p-3 card--glass">
      <Row
        className={classnames({
          'd-none': disabledFields.includes('name'),
        })}
      >
        <Col>
          <FormGroup
            className={classnames({
              'has-danger': errors.name,
            })}
          >
            <Label for="name" className="pb-2">
              {t('eshops.create_form.name')}
            </Label>
            <Input
              className={classnames({
                'form-control-danger': errors.name,
              })}
              id="name"
              name="name"
              type="text"
              placeholder={t('eshops.create_form.name_placeholder')}
              onBlur={() => trigger('name')}
              innerRef={register({
                required: t('form.required'),
              })}
              disabled={disabled || disabledFields.includes('name')}
            />
            <div className="form-control-feedback pt-1 text-left">
              {errors.name && errors.name.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row
        className={classnames({
          'd-none': disabledFields.includes('url'),
        })}
      >
        <Col>
          <FormGroup
            className={classnames({
              'has-danger': errors.url,
            })}
          >
            <Label for="url" className="pb-2">
              {t('eshops.create_form.url')}
            </Label>
            <Input
              className={classnames({
                'form-control-danger': errors.url,
              })}
              id="url"
              name="url"
              type="text"
              placeholder={t('eshops.create_form.url_placeholder')}
              onBlur={() => trigger('url')}
              innerRef={register({
                required: t('form.required'),
                pattern: {
                  value: /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/,
                  message: t('form.must_be_valid_url'),
                },
              })}
              disabled={disabled || disabledFields.includes('url')}
            />
            <div className="form-control-feedback pt-1 text-left">
              {errors.url && errors.url.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row
        className={classnames({
          'd-none': disabledFields.includes('feedUrl'),
        })}
      >
        <Col>
          <FormGroup
            className={classnames({
              'has-danger': errors.feedUrl,
            })}
          >
            <Label for="feedUrl" className="pb-2">
              {t('eshops.create_form.feed_url')}
            </Label>
            <Input
              className={classnames({
                'form-control-danger': errors.feedUrl,
              })}
              id="feedUrl"
              name="feedUrl"
              type="text"
              placeholder={t('eshops.create_form.feed_url_placeholder')}
              onBlur={() => trigger('url')}
              innerRef={register({
                required: t('form.required'),
                pattern: {
                  value: /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/,
                  message: t('form.must_be_valid_url'),
                },
              })}
              disabled={disabled || disabledFields.includes('feedUrl')}
            />
            <div className="form-control-feedback pt-1 text-left">
              {errors.feedUrl && errors.feedUrl.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row
        className={classnames({
          'd-none': disabledFields.includes('feedUpdateFrequency'),
        })}
      >
        <Col>
          <FormGroup
            className={classnames({
              'has-danger': errors.feedUpdateFrequency,
            })}
          >
            <Label for="feedUpdateFrequency" className="pb-2">
              {t('eshops.create_form.feed_update_frequency')}
            </Label>
            <Input
              className={classnames({
                'form-control-danger': errors.feedUpdateFrequency,
              })}
              id="feedUpdateFrequency"
              name="feedUpdateFrequency"
              type="select"
              innerRef={register({
                required: t('form.required'),
              })}
              disabled={
                disabled || disabledFields.includes('feedUpdateFrequency')
              }
            >
              {feedUpdateFrequencies.map((o) => (
                <option
                  value={o.value}
                  selected={o.value === values.feedUpdateFrequency}
                >
                  {t(
                    `eshops.create_form.feed_update_frequency_enum.${o.label}`
                  )}
                </option>
              ))}
            </Input>
            <div className="form-control-feedback pt-1 text-left">
              {errors.feedUpdateFrequency && errors.feedUpdateFrequency.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row
        className={classnames({
          'd-none': disabledFields.includes('platform'),
        })}
      >
        <Col>
          <FormGroup className="pt-3">
            <Label for="platform" className="pb-2">
              {t('eshops.create_form.select_platform')}
            </Label>
            <FormGroup className="platform-radio pt-2 pb-2 mb-0 text-center">
              {platforms.map((platform) => (
                <FormGroup className="mb-0">
                  <Label>
                    <Input
                      disabled={
                        platform.disabled ||
                        disabled ||
                        disabledFields.includes('platform')
                      }
                      type="radio"
                      name="platform"
                      value={platform.value}
                      style={{ display: 'none' }}
                      innerRef={register({
                        required: t(
                          'eshops.create_form.please_select_platform'
                        ),
                      })}
                    />
                    <img
                      id={`img-${platform.value}`}
                      alt={platform.value}
                      className={classnames(
                        'img-circle img-no-padding img-responsive platform-radio__item',
                        {
                          'platform-radio__item--inactive':
                            platform.value !== selectedPlatform &&
                            platform.disabled === false,
                          'platform-radio__item--active':
                            platform.value === selectedPlatform &&
                            platform.disabled === false,
                          'platform-radio__item--inactive--disabled':
                            platform.disabled === true,
                        }
                      )}
                      src={require(`assets/img/${platform.value}.png`)}
                    />
                    {platform.disabled ? (
                      <p className="platform_radio__subtitle">
                        {t('eshops.create_form.available_soon')}
                      </p>
                    ) : (
                      <p className="platform_radio__subtitle">
                        {platform.label}
                      </p>
                    )}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
            <div className="form-control-feedback color-r pt-1">
              {errors.platform && errors.platform.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row
        className={classnames({
          'd-none': disabledFields.includes('showRecommendations'),
        })}
      >
        <Col>
          <FormGroup
            className={classnames({
              'has-danger': errors.showRecommendations,
            })}
          >
            <Label for="showRecommendations" className="pb-2">
              {t('eshops.create_form.show_recommendations')}
            </Label>
            <Input
              className={classnames({
                'form-control-danger': errors.showRecommendations,
              })}
              id="showRecommendations"
              name="showRecommendations"
              type="select"
              innerRef={register({
                required: t('form.required'),
              })}
              disabled={
                disabled || disabledFields.includes('showRecommendations')
              }
            >
              {[
                { value: false, label: 'no' },
                { value: true, label: 'yes' },
              ].map((o) => (
                <option
                  value={o.value}
                  selected={o.value === values.showRecommendations}
                >
                  {t(`eshops.create_form.show_recommendations_enum.${o.label}`)}
                </option>
              ))}
            </Input>
            <div className="form-control-feedback pt-1 text-left">
              {errors.showRecommendations && errors.showRecommendations.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="profile-edit-form__button-group">
          <Button
            className={classnames('btn-fill btn-round', {
              disabled: Object.keys(formState.touched).length === 0,
            })}
            color="danger"
            onClick={handleDiscardClick}
          >
            {t('form.discard')}
          </Button>
          <Button
            className="btn-fill btn-round"
            color="success"
            disabled={!formState.isDirty}
          >
            {t('form.save')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

EshopForm.propTypes = {
  onDiscard: PropTypes.func,
  onSave: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    feedUrl: PropTypes.string,
    feedUpdateFrequency: PropTypes.string,
    platform: PropTypes.string,
  }),
  disabledFields: PropTypes.arrayOf(PropTypes.string),
};

EshopForm.defaultProps = {
  onDiscard: () => console.log('on discard handler'),
  onSave: () => console.log('on save handler'),
  defaultValues: {},
  disabled: false,
  disabledFields: [],
};

export default EshopForm;
