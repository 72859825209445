import AccountPage from 'pages/AccountPage';
import EshopsPage from 'pages/EshopsPage';
import HomePage from 'pages/HomePage';
import AddEshopPage from 'pages/AddEshopPage';
import EshopDetailPage from 'pages/EshopDetailPage';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Navbar from 'components/Navbars/Navbar';

function AppLayout() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/account" component={AccountPage} />
        <Route exact path="/eshops" component={EshopsPage} />
        <Route exact path="/eshops/add" component={AddEshopPage} />
        <Route path="/eshops/:id" component={EshopDetailPage} />
      </Switch>
    </>
  );
}

export default AppLayout;
