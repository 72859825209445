import React, { useEffect } from 'react';
import useAmplifyAuth from 'hooks/useAmplifyAuth';
import { Redirect } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

function LogoutPage() {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { handleSignOut } = useAmplifyAuth();

  const signOut = async () => {
    try {
      await handleSignOut();
      addToast(t('logout.logout_success'), {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      addToast(t('logout.logout_fail', { error }), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  useEffect(() => {
    signOut();
  }, []);

  return <Redirect to="/" />;
}

export default LogoutPage;
