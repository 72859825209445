import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useToasts } from 'react-toast-notifications';
import EshopListItem from 'components/EshopListItem';

const ALL_ESHOPS_QUERY = gql`
  query listEshops {
    listEshops {
      items {
        id
        name
        status
        platform
        url
        lastFeedUpdateAt
      }
    }
  }
`;

function EshopList() {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { data, loading, error } = useQuery(ALL_ESHOPS_QUERY);

  useEffect(() => {
    if (error) {
      addToast(t('errors.failed_to_load_data', { error }), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [error, addToast, t]);

  return (
    <ul className="list-unstyled follows">
      {loading ? (
        <li key="loading-placeholder">
          <EshopListItem
            id="loading-placeholder"
            name=""
            status=""
            platform=""
            url=""
            loading
          />
        </li>
      ) : (
        data.listEshops.items.map(
          ({ id, name, status, platform, url, lastFeedUpdateAt }) => (
            <li key={id}>
              <EshopListItem
                id={id}
                name={name}
                status={status}
                platform={platform}
                url={url}
                lastFeedUpdateAt={lastFeedUpdateAt}
              />
            </li>
          )
        )
      )}
    </ul>
  );
}

export default memo(EshopList);
