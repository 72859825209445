import React from 'react';
import PropTypes from 'prop-types';

function ErrorSkeleton({ children, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className="component-error" {...props}>
      {children}
    </div>
  );
}

ErrorSkeleton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ErrorSkeleton;
