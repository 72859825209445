import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAmplifyAuth from 'hooks/useAmplifyAuth';

const currentUserContext = createContext();

function useProvideCurrentUser() {
  const [user, setUser] = useState(null);
  const { state } = useAmplifyAuth();

  useEffect(() => {
    if (state.user) {
      setUser(state.user);
    }
  }, [state]);

  return {
    user,
  };
}

export function CurrentUserProvider({ children }) {
  const currentUser = useProvideCurrentUser();
  return (
    <currentUserContext.Provider value={currentUser}>
      {children}
    </currentUserContext.Provider>
  );
}

CurrentUserProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useCurrentUser = () => {
  return useContext(currentUserContext);
};
