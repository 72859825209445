import React from 'react';
import { Button, Card, Container, Row, Col } from 'reactstrap';
import { useHistory, useLocation, Link } from 'react-router-dom';
import LoginForm from 'components/LoginForm';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';

function LoginPage() {
  const { t } = useTranslation();
  const { push } = useHistory();
  const search = qs.parse(useLocation().search);

  const { login, password } = search;

  const onLogInSuccess = () => {
    push('/');
  };

  return (
    <Container>
      <Row>
        <Col className="ml-auto mr-auto" lg="4">
          <Card className="card-register ml-auto mr-auto">
            <h3 className="title mx-auto text-uppercase">{t('login.login')}</h3>
            <LoginForm
              onLogInSuccess={onLogInSuccess}
              defaultLogin={login}
              defaultPassword={password}
            />
            <Link
              to={{
                pathname: '/register',
              }}
            >
              <Button outline block className="btn-round" color="danger">
                {t('login.create_new_account')}
              </Button>
            </Link>
            <div className="forgot">
              <Link
                to={{
                  pathname: '/forgot-password',
                }}
              >
                <Button className="btn-link" color="danger">
                  {t('login.forgot_password')}?
                </Button>
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
