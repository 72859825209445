import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, Button, Form, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { useToasts } from 'react-toast-notifications';
import useAmplifyAuth from 'hooks/useAmplifyAuth';
import { useTranslation } from 'react-i18next';

function EditAccountForm({
  onLogInSuccess,
  onLogInFailure,
  defaultLogin,
  defaultPassword,
}) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { register, handleSubmit, errors, trigger, reset } = useForm();
  const { handleSignIn } = useAmplifyAuth();

  const onSubmit = async (data) => {
    const { email, password } = data;

    setIsLoggingIn(true);
    try {
      await handleSignIn({
        username: email,
        password,
      });
      addToast(t('login.logged_in'), {
        appearance: 'success',
        autoDismiss: true,
      });
      onLogInSuccess();
      reset();
    } catch (error) {
      addToast(t('login.failed_to_log_in', { error }), {
        appearance: 'error',
        autoDismiss: true,
      });
      onLogInFailure();
    }
    setIsLoggingIn(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="register-form">
      <Row>
        <Col>
          <FormGroup
            className={classnames({
              'has-danger': errors.email,
            })}
          >
            <Label for="email" className="pb-2 text-uppercase">
              {t('login.email')}
            </Label>
            <Input
              className={classnames({
                'form-control-danger': errors.email,
              })}
              id="email"
              name="email"
              type="text"
              onBlur={() => trigger('email')}
              innerRef={register({
                required: t('form.required'),
              })}
              placeholder={t('login.email_placeholder')}
              defaultValue={defaultLogin}
            />
            <div className="form-control-feedback pt-1 text-left">
              {errors.email && errors.email.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup
            className={classnames({
              'has-danger': errors.password,
            })}
          >
            <Label for="password" className="pb-2  text-uppercase">
              {t('login.password')}
            </Label>
            <Input
              className={classnames({
                'form-control-danger': errors.password,
              })}
              id="password"
              name="password"
              type="password"
              onBlur={() => trigger('password')}
              innerRef={register({
                required: t('form.required'),
              })}
              placeholder={t('login.password_placeholder')}
              defaultValue={defaultPassword}
            />
            <div className="form-control-feedback pt-1 text-left">
              {errors.password && errors.password.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            block
            className="btn-round"
            color="danger"
            disabled={isLoggingIn}
          >
            {isLoggingIn ? (
              <i className="fa fa-spinner fa-spin" aria-hidden="true" />
            ) : (
              t('login.log_in')
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

EditAccountForm.propTypes = {
  onLogInSuccess: PropTypes.func,
  onLogInFailure: PropTypes.func,
  defaultLogin: PropTypes.string,
  defaultPassword: PropTypes.string,
};

EditAccountForm.defaultProps = {
  onLogInSuccess: null,
  onLogInFailure: null,
  defaultLogin: '',
  defaultPassword: '',
};

export default EditAccountForm;
