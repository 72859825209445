import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useToasts } from 'react-toast-notifications';
import EshopStatus from 'components/EshopStatus';
import { Col, Row, Alert, Container } from 'reactstrap';
import EshopThumbnail from 'components/EshopThumbnail';

const GET_ESHOP_QUERY = gql`
  query getEshop($id: ID!) {
    getEshop(id: $id) {
      id
      name
      url
      platform
      status
      feedUrl
      feedUpdateFrequency
      platformInternalId
      lastFeedUpdateAt
    }
  }
`;

function EshopDetailHeader({ id }) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { data, loading, error } = useQuery(GET_ESHOP_QUERY, {
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (error) {
      addToast(t('errors.failed_to_load_user', { error }), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [error, addToast, t]);

  const { name, url, platform, status, lastFeedUpdateAt } = data
    ? data?.getEshop
    : {};

  return (
    <>
      <Row className="mb-2">
        <Col>
          <div className="card--glass">
            <Row>
              <Col>
                <div className="d-flex align-items-center">
                  <div>
                    {loading ? (
                      <Skeleton circle height={100} width={100} />
                    ) : (
                      <img
                        alt="..."
                        className="img-circle img-no-padding img-responsive m-0 mr-4"
                        src={require(`assets/img/${platform}.png`)}
                        style={{
                          width: '100px',
                        }}
                      />
                    )}
                  </div>
                  <div className="f-1">
                    <h2 className="mt-0">
                      {loading ? <Skeleton /> : `${name}`}
                    </h2>
                    <h6 className="mt-1 mb-2">
                      {loading ? <Skeleton /> : <EshopStatus status={status} />}
                    </h6>
                    <h5 className="fw-400">
                      {loading ? <Skeleton /> : `${url}`}
                    </h5>
                    <p className="font-italic">
                      {t('eshops.feed_updated')}:{' '}
                      {loading ? (
                        <Skeleton />
                      ) : (
                        lastFeedUpdateAt || t('general.n_a')
                      )}
                    </p>
                  </div>
                  <div className="d-flex">
                    <EshopThumbnail url={url} />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert className="alert-with-icon" color="info" isOpen>
            <Container>
              <div className="alert-wrapper">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  // onClick={() => setAlertDanger(false)}
                >
                  <i className="nc-icon nc-simple-remove" />
                </button>
                <div className="message">
                  <strong>{t('eshop.next_steps')}:</strong>{' '}
                  {t(`eshop.status_descriptions.${status}`)}
                </div>
              </div>
            </Container>
          </Alert>
        </Col>
      </Row>
    </>
  );
}

EshopDetailHeader.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EshopDetailHeader;
