// removeAttributes returns deep copy of object without defined attributes
function removeAttributes(obj, attributes) {
  const newObj = JSON.parse(JSON.stringify(obj));
  attributes.forEach((element) => {
    delete newObj[element];
  });
  return newObj;
}

export default removeAttributes;
