import { Auth } from 'aws-amplify';

export const login = async (email, password) => {
  await Auth.signIn(email, password);
};

export const logout = async () => {
  await Auth.signOut();
};

export const forgotPassword = async (email) => {
  await Auth.forgotPassword(email);
};

export const forgotPasswordSubmit = async (email, code, newPassword) => {
  await Auth.forgotPasswordSubmit(email, code, newPassword);
};

export const signUp = async (email, password) => {
  const user = await Auth.signUp({
    username: email,
    password,
  });
  return user;
};

export const confirmSignUp = async (email, code) => {
  await Auth.confirmSignUp(email, code);
};

// TODO: Auth.getcurrentuser
