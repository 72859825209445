import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, Container, Button } from 'reactstrap';
import EshopDetailHeader from 'components/EshopDetailHeader';
import { useTranslation } from 'react-i18next';
import EshopSettings from 'components/EshopSettings';

function EshopDetailPage() {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <Container className="pt-3">
      <Row className="mb-3 d-flex flex-row align-items-baseline">
        <Col>
          <h6 className="text-light-blue">{t('eshop.detail')}</h6>
        </Col>
        <Col className="text-right">
          <Link
            to={{
              pathname: '/eshops',
            }}
          >
            <Button className="btn-link" color="primary">
              <i className="fa fa-chevron-left" aria-hidden="true" />
              {t('eshops.create_form.back_to_list')}
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <EshopDetailHeader id={id} />
        </Col>
      </Row>
      <Row className="mb-3 d-flex flex-row align-items-baseline">
        <Col>
          <h6 className="text-light-blue">{t('eshop.settings')}</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <EshopSettings id={id} />
        </Col>
      </Row>
    </Container>
  );
}

export default EshopDetailPage;
