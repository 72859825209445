import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const LOCAL_STORAGE_LANGUAGE_KEY = 'i18n:language';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .on('languageChanged', (lng) => {
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, lng);
  })
  .init({
    fallbackLng: localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY) || 'en',
    debug: process.env.NODE_ENV !== 'production',
    detection: {
      order: ['queryString', 'cookie'],
      cache: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
