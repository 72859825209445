import LoginPage from 'pages/LoginPage';
import LogoutPage from 'pages/LogoutPage';
import NotFoundPage from 'pages/NotFoundPage';
import RegisterPage from 'pages/RegisterPage';
import ConfirmSignUpPage from 'pages/ConfirmSignUpPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

function PublicLayout() {
  return (
    <div
      className="page-header"
      style={{
        backgroundImage: `url(${require('assets/img/shapes.png')})`,
        backgroundColor: '#101932',
      }}
    >
      <div className="filter" />
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/logout" component={LogoutPage} />
        <Route exact path="/register" component={RegisterPage} />
        <Route exact path="/confirm-signup" component={ConfirmSignUpPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </div>
  );
}

export default PublicLayout;
