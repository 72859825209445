import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function statusToColor(status) {
  switch (status) {
    case 'UNCONFIRMED':
      return 'label-default';
    case 'VERIFIED':
      return 'label-primary';
    case 'CONNECTED':
      return 'label-info';
    case 'READY':
      return 'label-success';
    case 'RECOMMENDING':
      return 'label-success';
    case 'PAUSED':
      return 'label-warning';
    case 'DELETED':
      return 'label-warning';
    default:
      return 'label-default';
  }
}

function EshopStatus({ status }) {
  const { t } = useTranslation();
  return (
    <label className={`label ${statusToColor(status)} mr-1 text-white`}>
      {t(`eshops.statuses.${status}`)}
    </label>
  );
}

EshopStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default EshopStatus;
