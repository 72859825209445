import React from 'react';
import { Card, Container, Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import ConfirmSignUpForm from 'components/ConfirmSignUpForm';
import { useTranslation } from 'react-i18next';

function ConfirmSignUpPage() {
  const { t } = useTranslation();
  const { push } = useHistory();

  const onConfirmSignUpSuccess = () => {
    push('/');
  };

  return (
    <Container>
      <Row>
        <Col className="ml-auto mr-auto" lg="4">
          <Card className="card-register ml-auto mr-auto">
            <h3 className="title mx-auto">
              {t('confirm_signup.confirm_signup')}
            </h3>
            <ConfirmSignUpForm
              onConfirmSignUpSuccess={onConfirmSignUpSuccess}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ConfirmSignUpPage;
