import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import platforms from 'variables/platforms';
import EshopStatus from 'components/EshopStatus';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function EshopListItem({
  id,
  name,
  status,
  platform = 'woocommerce',
  url,
  lastFeedUpdateAt,
  loading,
}) {
  const { t } = useTranslation();

  return (
    <Link
      to={{
        pathname: `/eshops/${id}`,
      }}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <div className="eshop-list-item">
        <Row>
          <Col className="ml-auto mr-auto d-flex">
            <div className="mr-4">
              {loading ? (
                <Skeleton circle height={70} width={70} />
              ) : (
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive eshop-list-item__platform-icon"
                  src={require(`assets/img/${platform}.png`)}
                />
              )}
            </div>
            <div className="mr-4">
              <h4 className="m-0 text-white mb-2">
                {loading ? <Skeleton /> : name}
              </h4>
              <h5 className="d-block">{loading ? <Skeleton /> : url}</h5>
              <p className="font-italic">
                {t('eshops.feed_updated')}:{' '}
                {loading ? <Skeleton /> : lastFeedUpdateAt || t('general.n_a')}
              </p>
            </div>
          </Col>
          <Col className="d-flex flex-row-reverse f-0">
            <div className="text-center">
              <h6>
                {loading ? <Skeleton /> : <EshopStatus status={status} />}
              </h6>
            </div>
          </Col>
        </Row>
      </div>
    </Link>
  );
}

EshopListItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  platform: PropTypes.oneOf(platforms).isRequired,
  url: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  lastFeedUpdateAt: PropTypes.string,
};

EshopListItem.defaultProps = {
  loading: false,
  lastFeedUpdateAt: null,
};

export default EshopListItem;
