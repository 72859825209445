import React from 'react';
import PropTypes from 'prop-types';
import EditAccountform from 'components/EditAccountForm';

function AccountSettings({ id }) {
  return <EditAccountform id={id} />;
}

AccountSettings.propTypes = {
  id: PropTypes.string.isRequired,
};

export default AccountSettings;
