import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Index from 'views/Index';
import LandingPage from 'views/examples/LandingPage';
import ProfilePage from 'views/examples/ProfilePage';
import RegisterPage from 'views/examples/RegisterPage';

function DemoLayout(props) {
  return (
    <Switch>
      <Route exact path="/demo" component={Index} />
      <Route exact path="/demo/landing" component={LandingPage} />
      <Route exact path="/demo/profile" component={ProfilePage} />
      <Route exact path="/demo/register" component={RegisterPage} />
    </Switch>
  );
}

export default DemoLayout;
