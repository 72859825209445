import React from 'react';
import { Button, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import CreateEshopForm from 'components/CreateEshopForm';
import { useTranslation } from 'react-i18next';

function AddEshopPage() {
  const { t } = useTranslation();

  return (
    <Container className="pt-3">
      <Row className="mb-3 d-flex flex-row align-items-baseline">
        <Col>
          <h6 className="text-light-blue">
            {t('eshops.create_form.new_eshop')}
          </h6>
        </Col>
        <Col className="text-right">
          <Link
            to={{
              pathname: '/eshops',
            }}
          >
            <Button className="btn-link" color="primary">
              <i className="fa fa-chevron-left" aria-hidden="true" />
              {t('eshops.create_form.back_to_list')}
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col className="ml-auto mr-auto">
          <CreateEshopForm />
        </Col>
      </Row>
    </Container>
  );
}

export default AddEshopPage;
