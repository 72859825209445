import React from 'react';
import PropTypes from 'prop-types';
import EditEshopForm from 'components/EditEshopForm';

function EshopSettings({ id }) {
  return <EditEshopForm id={id} />;
}

EshopSettings.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EshopSettings;
