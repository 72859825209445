import React from 'react';
import PropTypes from 'prop-types';

function EshopThumbnail({ url, alt }) {
  return (
    <div className="eshop-thumbnail">
      <img src={`http://qs.jin.fi/?url=${url}`} alt={alt} className="" />
    </div>
  );
}

EshopThumbnail.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

EshopThumbnail.defaultProps = {
  alt: '',
};

export default EshopThumbnail;
