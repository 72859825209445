import React from 'react';
import Amplify from 'aws-amplify';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import PrivateRoute from 'components/PrivateRoute';
import Toast from 'components/Toast';
import PublicLayout from 'layouts/PublicLayout';
import AppLayout from 'layouts/AppLayout';
import DemoLayout from 'layouts/DemoLayout';
import { CurrentUserProvider } from 'hooks/useCurrentUser';
import { AccountProvider } from 'hooks/useAccount';
import { ApolloProvider } from '@apollo/react-hooks';
import createApolloClient from 'utils/apolloClient';

import config from './aws-exports';

Amplify.configure(config);

const client = createApolloClient({
  uri: config.aws_appsync_graphqlEndpoint,
  cache: true,
});

function App() {
  return (
    <ToastProvider components={{ Toast }}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <CurrentUserProvider>
            <AccountProvider>
              <Switch>
                {/* PUBLIC ROUTES */}
                <Route
                  exact
                  path={[
                    '/login',
                    '/logout',
                    '/register',
                    '/confirm-signup',
                    '/forgot-password',
                  ]}
                  component={PublicLayout}
                />

                {/* DEMO ROUTES */}
                <Route path={['/demo']} component={DemoLayout} />

                {/* APP ROUTES */}
                <PrivateRoute
                  path={['/', '/account', '/eshops']}
                  component={AppLayout}
                />

                {/* NOT FOUND FALLBACK ROUTES */}
                <Route path="*" component={PublicLayout} />
              </Switch>
            </AccountProvider>
          </CurrentUserProvider>
        </BrowserRouter>
      </ApolloProvider>
    </ToastProvider>
  );
}

export default App;
