import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './utils/i18n';
import App from './App';

// styles
import 'assets/css/bootstrap.min.css';
import 'assets/scss/paper-kit.scss';
import 'assets/demo/demo.css';

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <Suspense fallback={<div>Mocked loading screen...</div>}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>,
  // eslint-disable-next-line no-undef
  document.getElementById('root')
);
