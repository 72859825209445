import React, { useState } from 'react';
import { Alert, Container } from 'reactstrap';
import PropTypes from 'prop-types';

const DefaultColor = 'primary';

function Toast({ appearance, children }) {
  const [display, setDisplay] = useState(true);

  const handleCloseClick = () => {
    setDisplay(false);
  };

  let color = DefaultColor;

  switch (appearance) {
    case 'error':
      color = 'danger';
      break;
    case 'info':
      color = 'info';
      break;
    case 'success':
      color = 'success';
      break;
    case 'warning':
      color = 'warning';
      break;
    default:
      break;
  }

  return (
    <Alert color={color} isOpen={display} style={{ zIndex: 1000000 }}>
      <Container>
        <span>{children}</span>
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={handleCloseClick}
        >
          <i className="nc-icon nc-simple-remove ml-2" />
        </button>
      </Container>
    </Alert>
  );
}

Toast.propTypes = {
  appearance: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default Toast;
