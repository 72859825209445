import React from 'react';
import { Col, Row, Container } from 'reactstrap';

function NotFoundPage(props) {
  return (
    <Container>
      <Row>
        <Col>
          <h1 className="text-white">Page not found.</h1>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFoundPage;
