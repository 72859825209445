import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EshopList from 'components/EshopList';

function EshopsPage() {
  const { t } = useTranslation();

  return (
    <Container className="pt-3">
      <Row className="mb-3 d-flex flex-row align-items-baseline">
        <Col>
          <h6 className="text-light-blue">{t('general.eshops')}</h6>
        </Col>
        <Col className="text-right">
          <Link
            to={{
              pathname: '/eshops/add',
            }}
          >
            <Button className="btn-round" color="primary" outline>
              <i className="fa fa-plus" aria-hidden="true" />
              {t('eshops.add_eshop')}
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col className="ml-auto mr-auto" lg="12" md="8" sm="10">
          <EshopList />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col className="ml-auto mr-auto text-center" md="6" />
      </Row>
    </Container>
  );
}

export default EshopsPage;
