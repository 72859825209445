/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import useAmplifyAuth from 'hooks/useAmplifyAuth';

function PrivateRoute({ component: Component, ...rest }) {
  const {
    state: { user, isLoading },
  } = useAmplifyAuth();

  if (isLoading) {
    return (
      <div className="fullscreen-dimmer d-flex justify-content-center align-items-center">
        <h1 className="text-center">LOADING ACCOUNT</h1>
      </div>
    );
  }

  if (user === null) {
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
